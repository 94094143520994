import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ImageMap from "../components/ImageMap"
import SEO from "../components/Seo"

const VirtualRoom = ({ pageContext, data }) => {
  const { wpVirtualRoom } = data
  return (
    <Layout>
        <SEO title={wpVirtualRoom.title}/>
      <ImageMap data={wpVirtualRoom.SingleVirtualRoom} />
    </Layout>
  )
}

export const query = graphql`
    query virtualRoom($slug: String!) {
        wpVirtualRoom(slug: {eq: $slug}) {
            SingleVirtualRoom {
                background {
                    localFile {
                        childImageSharp {
                            fixed(width: 1920) {
                                src
                            }
                            original {
                                src
                            }
                        }
                    }
                }
                roomSettings
                elencoAree {
                    actionId
                    hotspotArea
                    immagineArea {
                        localFile {
                            childImageSharp {
                                fluid {
                                    src
                                }
                            }
                        }
                    }
                    opzioniArchivioArticoli {
                        ... on WpPost {
                            id
                            slug
                        }
                    }
                    opzioniCalendario
                    opzioniCalendarioCustom
                    opzioniChat
                    opzioniChat1to1
                    opzioniChatCustom
                    opzioniLeaderboard
                    opzioniLinkBrowser
                    opzioniLinkInterno {
                        ... on WpVirtualRoom {
                            id
                            slug
                        }
                    }
                    opzioniFileMultiplo {
                        file {
                            localFile {
                                publicURL
                            }
                        }
                        nomeFile
                    }
                    opzioniImmaginiGalleria {
                        immagineGalleriaSingola {
                            localFile {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                        }
                    }
                    opzioniVideochiamata
                    opzioniVideoGalleria {
                        videoGalleriaUrl
                    }
                    opzioniWebinar
                    tipoArea
                    tooltipArea
                }
            }
            title
            slug
        }
    }
`

export default VirtualRoom
